export function getPrivateRostar() {
  const data = {
    headers: [
      {
        value: "date",
        text: "日付"
      },
      {
        value: "office",
        text: "事業所"
      },
      {
        value: "shift",
        text: "シフト"
      },
      {
        value: "start",
        text: "開始"
      },
      {
        value: "end",
        text: "終了"
      },
      {
        value: "break",
        text: "休憩"
      },
      {
        value: "work",
        text: "実働"
      },
      {
        value: "over",
        text: "超過"
      },
      {
        value: "midnight",
        text: "深夜"
      },
      {
        value: "distance",
        text: "距離"
      },
      {
        value: "remarks",
        text: "備考"
      }
    ],
    body: [],
    summary: []
  };
  return data;
}

export function getOfficeRostar() {
  return {
    headers: [
      {
        value: "date",
        text: "日付"
      },
      {
        value: "username",
        text: "氏名"
      },
      {
        value: "shift",
        text: "シフト"
      },
      {
        value: "start",
        text: "開始"
      },
      {
        value: "end",
        text: "終了"
      },
      {
        value: "break",
        text: "休憩"
      },
      {
        value: "work",
        text: "実働"
      },
      {
        value: "over",
        text: "超過"
      },
      {
        value: "midnight",
        text: "深夜"
      },
      {
        value: "distance",
        text: "距離"
      },
      {
        value: "remarks",
        text: "備考"
      }
    ],
    body: []
  };
}

export function getDefaultTable() {
  return {
    name: "Dummy Data",
    headers: [
      {
        text: "Dessert (100g serving)",
        align: "start",
        sortable: false,
        value: "name"
      },
      { text: "Calories", value: "calories" },
      { text: "Fat (g)", value: "fat" },
      { text: "Carbs (g)", value: "carbs" },
      { text: "Protein (g)", value: "protein" },
      { text: "Iron (%)", value: "iron" }
      // { text: "actions", value: "actions" }
    ],
    items: [
      {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%"
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%"
      },
      {
        name: "Eclair",
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: "7%"
      },
      {
        name: "Cupcake",
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        iron: "8%"
      },
      {
        name: "Gingerbread",
        calories: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9,
        iron: "16%"
      },
      {
        name: "Jelly bean",
        calories: 375,
        fat: 0.0,
        carbs: 94,
        protein: 0.0,
        iron: "0%"
      },
      {
        name: "Lollipop",
        calories: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0,
        iron: "2%"
      },
      {
        name: "Honeycomb",
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: "45%"
      },
      {
        name: "Donut",
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: "22%"
      },
      {
        name: "KitKat",
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: "6%"
      }
    ]
  };
}

export function getShiftPattern() {
  return {
    name: "シフトパターン",
    key: "name",
    headers: [
      { text: "シフトパターン名", value: "name" },
      { text: "略称", value: "abbreviate" },
      { text: "勤務開始", value: "start" },
      { text: "勤務終了", value: "end" },
      { text: "休憩時間", value: "break" },
      { text: "勤務時間", value: "work" },
      { text: "時間単価", value: "price" },
      { text: "深夜計算", value: "midnight" }
    ],
    controls: [
      { label: "シフトパターン名", name: "name", required: true },
      { label: "略称", name: "abbreviate", required: true },
      { label: "勤務開始", name: "start", type: "time" },
      { label: "勤務終了", name: "end", type: "time" },
      { label: "休憩時間", name: "break", type: "hour" },
      { label: "勤務時間", name: "work", type: "hour" },
      { label: "時間単価", name: "price", type: "money" },
      { label: "深夜計算", name: "midnight" }
    ],
    items: [
      {
        name: "通常",
        abbreviate: "〇",
        start: "9:00",
        end: "18:00",
        break: 1.0,
        work: 8.0,
        price: 1000,
        midnight: true
      },
      {
        name: "パターンA",
        abbreviate: "A",
        start: "5:00",
        end: "14:00",
        break: 1.0,
        work: 8.0,
        price: 1200,
        midnight: false
      },
      {
        name: "パターンB",
        abbreviate: "B",
        start: "15:00",
        end: "19:00",
        break: 1.0,
        work: 8.0,
        price: 1000,
        midnight: false
      },
      {
        name: "パターンC",
        abbreviate: "C",
        start: "18:00",
        end: "22:00",
        break: 1.0,
        work: 8.0,
        price: 1000,
        midnight: true
      },
      {
        name: "パターンD",
        abbreviate: "D",
        start: "21:00",
        end: "6:00",
        break: 1.0,
        work: 8.0,
        price: 1000,
        midnight: true
      }
    ]
  };
}

export function getSystemPattern() {
  return {
    name: "シフトパターン",
    key: "name",
    headers: [
      { text: "シフトパターン名", value: "name" },
      { text: "略称", value: "abbreviate" },
      { text: "勤務開始", value: "start" },
      { text: "勤務終了", value: "end" },
      { text: "休憩時間", value: "break" },
      { text: "勤務時間", value: "work" },
      { text: "時間単価", value: "price" },
      { text: "深夜計算", value: "midnight" }
    ],
    controls: [
      { label: "シフトパターン名", name: "name", required: true },
      { label: "略称", name: "abbreviate", required: true },
      { label: "勤務開始", name: "start", type: "time" },
      { label: "勤務終了", name: "end", type: "time" },
      { label: "休憩時間", name: "break", type: "hour" },
      { label: "勤務時間", name: "work", type: "hour" },
      { label: "時間単価", name: "price", type: "money" },
      { label: "深夜計算", name: "midnight" }
    ],
    items: [
      {
        name: "有給",
        abbreviate: "有"
      },
      {
        name: "休み",
        abbreviate: "休"
      },
      {
        name: "午前休",
        abbreviate: "前"
      },
      {
        name: "午後休",
        abbreviate: "後"
      }
    ]
  };
}

export function getPersons(n = 10) {
  const lasts = [
    "佐藤",
    "鈴木",
    "高橋",
    "田中",
    "伊藤",
    "渡辺",
    "山本",
    "中村",
    "小林",
    "加藤",
    "吉田",
    "山田",
    "佐々木",
    "山口",
    "松本",
    "井上",
    "木村",
    "林",
    "斎藤",
    "清水",
    "山崎",
    "森",
    "池田",
    "橋本",
    "阿部",
    "石川",
    "山下",
    "中島",
    "石井",
    "小川",
    "前田",
    "岡田",
    "長谷川",
    "藤田",
    "後藤",
    "近藤",
    "村上",
    "遠藤",
    "青木",
    "坂本",
    "斉藤",
    "福田",
    "太田",
    "西村",
    "藤井",
    "金子",
    "岡本",
    "藤原",
    "三浦",
    "中野",
    "中川",
    "原田",
    "松田",
    "竹内",
    "小野",
    "田村",
    "中山",
    "和田",
    "石田",
    "森田",
    "上田",
    "原",
    "内田",
    "柴田",
    "酒井",
    "宮崎",
    "横山",
    "高木",
    "安藤",
    "宮本",
    "大野",
    "小島",
    "谷口",
    "工藤",
    "今井",
    "高田",
    "増田",
    "丸山",
    "杉山",
    "村田",
    "大塚",
    "新井",
    "小山",
    "平野",
    "藤本",
    "河野",
    "上野",
    "野口",
    "武田",
    "松井",
    "千葉",
    "菅原",
    "岩崎",
    "木下",
    "久保",
    "佐野",
    "野村",
    "松尾",
    "菊地",
    "市川"
  ];

  const firsts = [
    "樹",
    "蓮",
    "陽翔",
    "律",
    "朝陽",
    "湊",
    "新",
    "大和",
    "大翔",
    "暖",
    "悠真",
    "颯真",
    "湊斗",
    "陽斗",
    "颯太",
    "伊織",
    "悠人",
    "結翔",
    "碧",
    "陽向",
    "陸",
    "結斗",
    "凪",
    "悠斗",
    "陽太",
    "葵",
    "絢斗",
    "朔",
    "奏太",
    "颯",
    "旭",
    "岳",
    "陽",
    "陽大",
    "碧人",
    "湊大",
    "悠",
    "颯斗",
    "一颯",
    "瑛斗",
    "碧斗",
    "陸斗",
    "櫂",
    "瑛翔",
    "奏汰",
    "蒼空",
    "蒼太",
    "悠翔",
    "遥斗",
    "怜",
    "瑛太",
    "健人",
    "晴",
    "壮真",
    "太陽",
    "大晴",
    "大智",
    "湊介",
    "翼",
    "琉生",
    "海斗",
    "海翔",
    "空",
    "光希",
    "仁",
    "蒼士",
    "蒼大",
    "慧",
    "晴翔",
    "煌",
    "煌大",
    "啓太",
    "慶",
    "健斗",
    "光",
    "航",
    "晴大",
    "奏多",
    "大誠",
    "湊翔",
    "優",
    "悠生",
    "翔",
    "颯人",
    "綾人",
    "結人",
    "匠海",
    "新太",
    "迅",
    "蒼生",
    "大知",
    "悠仁",
    "理仁",
    "涼太",
    "颯汰",
    "一翔",
    "桔平",
    "圭",
    "健",
    "潤",
    "匠",
    "丈",
    "晴斗",
    "奏",
    "奏斗",
    "蒼人",
    "蒼翔",
    "尊",
    "湊士",
    "悠希",
    "悠誠",
    "諒真",
    "和真",
    "翔太",
    "陽葵",
    "凛",
    "詩",
    "結菜",
    "結愛",
    "莉子",
    "結月",
    "紬",
    "澪",
    "結衣",
    "葵",
    "咲良",
    "陽菜",
    "芽依",
    "凪",
    "美桜",
    "愛",
    "花",
    "朱莉",
    "心春",
    "美月",
    "さくら",
    "杏",
    "芽生",
    "楓",
    "杏奈",
    "蘭",
    "和花",
    "ひかり",
    "琴音",
    "琴葉",
    "彩葉",
    "あかり",
    "咲希",
    "心咲",
    "栞奈",
    "紗菜",
    "陽菜乃",
    "愛菜",
    "結",
    "光莉",
    "七海",
    "心結",
    "柚希",
    "柚葉",
    "凜",
    "すず",
    "はな",
    "一華",
    "心音",
    "陽咲",
    "莉央",
    "菫",
    "ひまり",
    "一花",
    "花音",
    "華",
    "心陽",
    "綾乃",
    "光",
    "咲",
    "千愛",
    "美海",
    "美結",
    "百花",
    "風花",
    "明莉",
    "優月",
    "莉緒",
    "いろは",
    "すみれ",
    "愛莉",
    "桜",
    "実桜",
    "紗奈",
    "紗良",
    "晴",
    "乃愛",
    "美緒",
    "文乃",
    "未来",
    "柚月",
    "和",
    "栞",
    "莉愛",
    "依茉",
    "芽衣",
    "結香",
    "彩心",
    "咲来",
    "咲茉",
    "詩織",
    "心",
    "千晴",
    "紬葵",
    "紬希",
    "美羽",
    "美波",
    "美優",
    "柚乃",
    "玲奈",
    "和奏"
  ];

  const rand = n => Math.floor(Math.random() * n);

  return [...Array(n)].map((x, i) => ({
    name: `${lasts[rand(lasts.length)]} ${firsts[rand(firsts.length)]}`,
    incode: "2020" + ("00" + (i + 1)).slice(-3)
  }));
}
